<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { onMounted, ref } from "vue";
// import i18n from "@/i18n";
import MethodService from "@/service/MethodService";
import toastr from "toastr";
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
import LanguaService from "@/service/LanguageService";
const lang = ref(lang_web.showLang.toLocaleUpperCase());

const checkLogo = ref(false);
const idLogo = ref("");
const urlLogo = ref("");
const urlInput = ref("");
const errorUrl = ref(false);
const errorTimeRange = ref(false);
const timeRange = ref("");
const showDeleteInputUrl = ref(false);
const loadingBtn = ref(false);
const loadingBtnLanguage = ref(false);
const idUrlConfig = ref("");
const idTimeRangeConfig = ref("");
const flagLanguage = ref("");
const flagSrc = ref("");
const flagOptions = [
  {
    flag: require("@/assets/images/flags/vietnam.svg"),
    language: "vi",
    title: "Việt Nam",
  },
  {
    flag: require("@/assets/images/flags/us.svg"),
    language: "en",
    title: "English",
  },
];

const getUrlConfig = async () => {
  try {
    const response = await mushroom.system_config.listAsync({
      filters: "key=user_url",
    });
    if (response.result.length > 0) {
      urlInput.value = response.result[0].value;
      idUrlConfig.value = response.result[0].id;
    } else {
      const system_config_url = {
        key: "user_url",
        value: "https://",
        is_public: true,
        note: "user_url",
      };
      const newIdUrlConfig = await mushroom.system_config.createAsync(
        system_config_url
      );
      idUrlConfig.value = newIdUrlConfig.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getTimeRangeConfig = async () => {
  try {
    const response = await mushroom.system_config.listAsync({
      filters: "key=time_range",
    });
    if (response.result.length > 0) {
      timeRange.value = response.result[0].value;
      idTimeRangeConfig.value = response.result[0].id;
    } else {
      const system_config_time_range = {
        key: "time_range",
        value: "0",
        is_public: true,
        note: "time_range",
      };
      const newIdTimeRangeConfig = await mushroom.system_config.createAsync(
        system_config_time_range
      );
      timeRange.value = undefined;
      idTimeRangeConfig.value = newIdTimeRangeConfig.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

// logo
const getLogo = async () => {
  try {
    const response = await mushroom.system_config.listAsync({
      filters: "key=logo",
    });
    if (response.result.length > 0) {
      checkLogo.value = true;
      idLogo.value = response.result[0].id;
      urlLogo.value = mushroom.$file.linkBuilder.thumb
        .id(response.result[0].value)
        .build();
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const createFileLogo = async (value) => {
  if (checkLogo.value) {
    const system_config_object = {
      id: idLogo.value, // required
      value: value,
    };
    try {
      const response = await mushroom.system_config.partialUpdateAsync(
        system_config_object
      );
      if (response.result == idLogo.value) {
        toastr.success(LanguaService[lang.value].toastr_update_success);
        getLogo();
      } else
        toastr.warning(LanguaService[lang.value].toastr_not_change_warning);
    } catch (error) {
      console.error("Có lỗi: %o", error);
    }
  } else {
    const system_config_object = {
      key: "logo",
      value: value,
      is_public: true,
      note: "Id của Logo",
    };
    try {
      const newId = await mushroom.system_config.createAsync(
        system_config_object
      );
      if (newId.result)
        toastr.success(LanguaService[lang.value].toastr_add_success);
      getLogo();
    } catch (error) {
      console.error("Có lỗi: %o", error);
    }
  }
};

const uploadLogo = async () => {
  try {
    let nameImage = document.getElementById("uploadLogo").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg"
      ) {
        toastr.warning(LanguaService[lang.value].t_select_format_image);
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadLogo").files[0],
        });
        if (res.result) {
          createFileLogo(res.result);
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const deleteRecord = async () => {
  checkLogo.value = false;
  try {
    await mushroom.system_config.deleteAsync({
      id: idLogo.value, // required
    });
    document.getElementById("uploadLogo").value = "";
    toastr.success(LanguaService[lang.value].toastr_delete_success);
    getLogo();
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const updateUrl = async () => {
  loadingBtn.value = true;
  errorUrl.value = false;
  let checkUrl = /^(https?:)?\/\//;
  if (!urlInput.value.trim().match(checkUrl)) {
    errorUrl.value = true;
    loadingBtn.value = false;
  } else {
    urlInput.value = urlInput.value.replace(/\s/g, "");
    try {
      const system_config_url = {
        id: idUrlConfig.value,
        key: "user_url",
        value: urlInput.value,
        is_public: true,
        note: urlInput.value,
      };
      const response = await mushroom.system_config.partialUpdateAsync(
        system_config_url
      );
      if (response.result == system_config_url.id)
        toastr.success(LanguaService[lang.value].toastr_update_success);
    } catch (e) {
      MethodService.showError(e.code);
    } finally {
      setTimeout(() => {
        loadingBtn.value = false;
      }, 2000);
    }
    errorUrl.value = false;
  }
  showDeleteInputUrl.value = false;
};
const deleteUrl = () => {
  urlInput.value = "";
  showDeleteInputUrl.value = false;
};
const clearErrorInput = () => {
  errorUrl.value = false;
  showDeleteInputUrl.value = true;
};
const changeSelectFlag = (flag) => {
  if (flag === "vi")
    flagSrc.value = require("@/assets/images/flags/vietnam.svg");
  if (flag === "en") flagSrc.value = require("@/assets/images/flags/us.svg");
  // tiep cac flag o day...
};
const updateLanguage = async () => {
  // tạo mới|cập nhật cấu hình user_config
  try {
    const resCheckHaveLanguage = await mushroom.system_config.listAsync({
      filters: ["key=language_default"],
    });
    if (resCheckHaveLanguage.result?.length === 0) {
      const newId = await mushroom.system_config.createAsync({
        key: "language_default",
        value: flagLanguage.value,
        is_public: true,
      });
      if (newId) {
        localStorage.setItem("language_default", flagLanguage.value);
        toastr.success(LanguaService[lang.value].toastr_update_success);
      }
    } else {
      const response = await mushroom.system_config.partialUpdateAsync({
        id: resCheckHaveLanguage.result[0].id,
        value: flagLanguage.value,
        is_public: true,
      });
      if (response.result == resCheckHaveLanguage.result[0].id) {
        localStorage.setItem("language_default", flagLanguage.value);
        toastr.success(LanguaService[lang.value].toastr_update_success);
      } else
        toastr.warning(LanguaService[lang.value].toastr_not_change_warning);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const setLanguage = async () => {
  const res = await mushroom.system_config.listAsync({
    filters: ["key=language_default"],
  });
  if (res.result?.length > 0) {
    flagLanguage.value = res.result[0].value;
    changeSelectFlag(flagLanguage.value);
  } else flagSrc.value = require("@/assets/icon/none-icon-menu.svg");
};
onMounted(async () => {
  setLanguage();
  await getLogo();
  await getUrlConfig();
  await getTimeRangeConfig();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 400px">
      <div class="card-header" style=" display: flex; justify-content: space-between ">
        <h3 class="text-uppercase mb-0">{{ $t("t-setup-primary") }}</h3>
      </div>
      <div class="card-body" style="padding: 1rem">
        <div style="margin-bottom: 40px" class="option__language">
          <h5>{{ $t("t_language_default") }}</h5>
          <div class="option__language--group">
            <img :src="flagSrc" alt="flag" />
            <el-select
              v-model="flagLanguage"
              @change="changeSelectFlag"
              :placeholder="$t('t_select_flag')"
            >
              <el-option
                v-for="item in flagOptions"
                :key="item.language"
                :label="item.title"
                :value="item.language"
              />
            </el-select>
            <el-button
              type="primary"
              @click="updateLanguage"
              :loading="loadingBtnLanguage"
            >
              {{ $t("t-update") }}</el-button
            >
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <h5>{{ $t("t-config") }} URL</h5>
          <div class="position-relative">
            <div class="option__url">
              <el-input
                v-model="urlInput"
                :placeholder="`${$t('t-place-url')}`"
                @focus="clearErrorInput"
                @change="urlInput = urlInput.replace(/\s/g, '')"
              />
              <button
                v-show="showDeleteInputUrl"
                @click="deleteUrl"
                type="button"
                class="btn btn-danger btn-icon waves-effect waves-light"
                style="height: 32px; margin-right: 5px"
              >
                <i class="ri-delete-bin-5-line"></i>
              </button>
              <el-button
                type="primary"
                @click="updateUrl"
                :loading="loadingBtn"
              >
                {{ $t("t-update") }}</el-button
              >
            </div>
            <Transition>
              <span v-show="errorUrl" class="errorUrl">{{
                $t("t-please-true-url")
              }}</span>
            </Transition>
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <h5>{{ $t("t_config_day_limit") }}</h5>
          <div class="position-relative">
            <div class="time-range-config">
              <el-input-number
                v-model.number="timeRange"
                :min="0"
                :step="1"
                :step-strictly="true"
                :placeholder="`${$t('t-place-time-range')}`"
                @focus="clearErrorInputTimeRange"
                controls-position="right"
                style="width: 280px"
              />
              <el-button
                type="primary"
                @click="updateTimeRange"
                :loading="loadingBtnTimeRange"
              >
                {{ $t("t-update") }}</el-button
              >
            </div>
            <Transition>
              <span v-show="errorTimeRange" class="errorUrl">{{
                $t("t-please-true-time-range")
              }}</span>
            </Transition>
          </div>
        </div>

        <div>
          <h5>
            {{ $t("t-config") }} logo ({{ $t("t_type") }}: .png, .jpg, .jpeg)
          </h5>
          <div
            class="dropzone position-relative"
            style="
              height: 260px;
              row-gap: 0;
              min-height: 40px;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            "
          >
            <div class="upload-logo">
              <div
                class="dropzone position-relative"
                style="
                  height: 200px;
                  row-gap: 0;
                  min-height: 40px;
                  margin-left: 18px;
                "
              >
                <div class="mb-1">
                  <label for="uploadLogo" style="background-color: #fff">
                    <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
                  </label>
                </div>
                <h4>{{ $t("t-upload") }}</h4>
                <input
                  type="file"
                  id="uploadLogo"
                  style="display: none"
                  @change="uploadLogo"
                />
              </div>
            </div>
            <div class="image-logo">
              <div class="image-zone">
                <img
                  v-if="checkLogo"
                  style="max-width: 100%; max-height: 100%"
                  :src="urlLogo"
                  alt="Logo"
                  id="image"
                />
                <div
                  v-else
                  style="
                    text-align: center;
                    font-size: 30px;
                    color: #000;
                    font-weight: 900;
                  "
                >
                  CEM
                </div>
              </div>
              <div class="image-delete" v-if="checkLogo">
                <div class="flex-shrink-0 ms-3">
                  <button
                    data-dz-remove=""
                    class="btn btn-sm btn-danger"
                    @click="deleteRecord"
                  >
                    {{ $t("t-delete") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.errorUrl {
  color: red;
  position: absolute;
  bottom: -24px;
}
.upload-logo {
  width: 60%;
  text-align: center;
}
.image-logo {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.image-zone {
  border: 1px dashed #409eff;
  border-radius: 5px;
  padding: 5px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 15px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.option {
  &__url {
    display: flex;
    border: 1px solid #e9ebec;
    border-radius: 5px;
    padding: 3px;
    :deep .el-input__wrapper {
      box-shadow: none;
    }
  }
  &__language {
    display: flex;
    align-items: center;
    h5 {
      margin-bottom: 0;
      margin-right: 20px;
    }
    .el-input__wrapper {
      box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset !important;
    }
    button {
      margin-left: 10px;
    }
    &--group {
      display: flex;
      img {
        height: 32px;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
  }
}
</style>
